import React from "react";
import {Outlet} from "react-router-dom";
import {Container} from '@mui/material';

import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Layout({ isLoggedIn }) {
    return (
        <>
            <main>
                <Container maxWidth="xl">
                    <Header isLoggedIn={isLoggedIn} />
                    <div className="content">
                        <Outlet/>
                    </div>
                    <Footer/>
                </Container>
            </main>
        </>
    )
        ;
}

export default Layout;