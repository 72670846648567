import React, {Fragment, useRef, useState} from "react";
import {Button, InputBase, TextField} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

import Meta from "../../components/Head/Meta";
import AlertDialog from "../../components/Dialog/AlertDialog";

import "./contact.css";

export default function Contact() {
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [object, setObject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const recaptcha = useRef();
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "url": "https://www.projets-opacity.fr/contact",
        "contactType": "Contactez-nous",
        "areaServed": "FR",
        "availableLanguage": "Français"
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()

        setLoading(true);
        setSuccess(false);
        setError(null);

        if (!captchaValue) {
            setError('Erreur avec le reCAPTCHA');
            setLoading(false);
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/emails/contact`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        firstname,
                        lastname,
                        object,
                        message,
                        captchaValue
                    }),
                });

                if (!response.ok) {
                    setError("Une erreur est survenue lors de le l'envoie.");
                    return;
                } else {
                    setSuccess(true);
                }

                setEmail('');
                setFirstname('');
                setLastname('');
                setObject('');
                setMessage('');
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Meta title="Contactez-nous - Projets Opacity" jsonLd={jsonLd}
                  description="Contactez-nous facilement ou rejoignez notre Discord ! Nous sommes là pour répondre à vos questions et prendre en compte vos commentaires."/>
            <h1 className="hide-title">Contactez-nous</h1>
            <section className="contact flex-row">
                <div className="socials-networks flex-column">
                    <div className="socials-contact bg-content-primary flex-column flex-justify-start">
                        <h2>Rejoignez nos réseaux sociaux</h2>
                        <div className="links-contact flex-row flex-justify-center flex-align-center flex-wrap">
                            <div className="flex-row flex-align-center">
                                <span>
                                    <img src="/assets/images/twitter.png" alt="Logo du réseau social X"/>
                                </span>
                                <a className="stretch-link" href="https://x.com/ProjetsOpacity" target="_blank"
                                   title="Rejoignez-nous sur X" aria-labelledby="X" rel="noreferrer"></a>
                            </div>
                            <div className="flex-row flex-align-center">
                                <span>
                                    <img src="/assets/images/linkedin.png" alt="Logo du réseau social LinkedIn"/>
                                </span>
                                <a className="stretch-link" href="https://www.linkedin.com/company/projetsopacity"
                                   target="_blank" title="Rejoignez-nous sur LinkedIn" aria-labelledby="LinkedIn"
                                   rel="noreferrer"></a>
                            </div>
                            <div className="flex-row flex-align-center">
                                <span>
                                    <img src="/assets/images/facebook.png" alt="Logo du réseau social Facebook"/>
                                </span>
                                <a className="stretch-link" href="https://facebook.com/projetsopacity"
                                   target="_blank" title="Rejoignez-nous sur Facebook" aria-labelledby="Facebook"
                                   rel="noreferrer"></a>
                            </div>
                            <div className="flex-row flex-align-center">
                                <span>
                                    <img src="/assets/images/teamspeak3.png" alt="Logo de Teamspeak3"/>
                                </span>
                                <a className="stretch-link" href="ts3client://ts3.projets-opacity.fr"
                                   target="_blank" title="Rejoignez-nous sur Teamspeak3" aria-labelledby="Teamspeak3"
                                   rel="noreferrer"></a>
                            </div>
                        </div>
                    </div>
                    <div className="discord-contact bg-content-primary flex-column flex-justify-start">
                        <h2>Rejoignez notre serveur Discord</h2>
                        <div className="widget-contact">
                            <iframe
                                title="Rejoindre le serveur Discord de l'association"
                                src="https://discord.com/widget?id=592030009158533120&theme=dark"
                                width="100%"
                                height="400"
                                frameBorder="0"
                                sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div className="mail-contact bg-content-primary flex-column flex-justify-start">
                    <h2>Contactez-nous par mail</h2>

                    <form className="flex-column flex-align-center" onSubmit={handleSubmit}>
                        <div className="flex-row">
                            <label>
                                Prénom*
                                <InputBase
                                    type="text"
                                    value={firstname}
                                    placeholder="John"
                                    className="input-form" onChange={(e) => setFirstname(e.target.value)}
                                    required={true}
                                />
                            </label>
                            <label>
                                Nom*
                                <InputBase
                                    type="text"
                                    value={lastname}
                                    placeholder="Doe"
                                    className="input-form" onChange={(e) => setLastname(e.target.value)}
                                    required={true}
                                />
                            </label>
                        </div>
                        <label>
                            Adresse e-mail*
                            <InputBase
                                type="email"
                                value={email}
                                placeholder="exemple@projets-opacity.fr"
                                className="input-form" onChange={(e) => setEmail(e.target.value)}
                                required={true}
                            />
                        </label>
                        <label>
                            Objet*
                            <InputBase
                                type="text"
                                value={object}
                                placeholder="Objet de la demande"
                                className="input-form" onChange={(e) => setObject(e.target.value)}
                                required={true}
                            />
                        </label>
                        <label>
                            Message*
                            <TextField
                                multiline
                                rows={3}
                                value={message}
                                placeholder="Votre message"
                                className="textarea-form"
                                onChange={(e) => setMessage(e.target.value)}
                                required={true}
                            />
                        </label>

                        <ReCAPTCHA
                            ref={recaptcha}
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        />

                        {error && <p className="error-form">{error}</p>}

                        <Button type="submit" variant="contained" color="primary" className="submit-form"
                                disabled={loading}>{loading ? 'Envoie en cours...' : 'Envoyer'}</Button>
                        <p className="subtext">* Obligatoire</p>
                    </form>
                </div>
            </section>

            {success && (
                <AlertDialog
                    title="Message de contact envoyé"
                    description="Votre message de contact a bien été envoyé à l'association Projets Opacity."
                    isOpen={true}
                />
            )}
        </>
    );
}