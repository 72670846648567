import React, {Fragment} from "react";

import Meta from "../../components/Head/Meta";
import SteamAuth from "../../components/Steam/SteamAuth";

import "./profile.css";

export default function Profile() {
    const userName = localStorage.getItem('userName');

    return (
        <>
            <Meta title="Mon profil - Projets Opacity"
                  description="Gérez votre profil en toute simplicité et personnalisez vos paramètres. Votre espace personnel vous attend !"/>
            <section className="profile bg-content-primary flex-column flex-justify-start flex-align-start">
                <h1>Mon profil</h1>
                <p className="subtitle">
                    Bienvenue {userName && userName} sur ta page de profil.
                </p>
                <h2>Lier Steam à son compte Projets Opacity</h2>
                <SteamAuth/>
            </section>
        </>
    );
}