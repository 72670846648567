import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import axios from 'axios';

import Layout from './components/Layout/Layout';
import LayoutForms from "./components/Layout/LayoutForms";
import Logout from "./components/Connect/Logout";

import Homepage from "./pages/Homepage/Homepage";
import Project from "./pages/Project/Project";
import About from "./pages/Association/About";
import Join from "./pages/Association/Join";
import Sustain from "./pages/Association/Sustain";
import Member from "./pages/Association/Member";
import Register from "./pages/Connect/Register";
import Login from "./pages/Connect/Login";
import Profile from "./pages/Profile/Profile";
import Contact from "./pages/Contact/Contact";
import Legal from "./pages/Legal/Legal";
import NotFound from "./pages/NotFound/NotFound";
import ResetPassword from "./pages/Connect/ResetPassword";
import ResetPasswordRequest from "./pages/Connect/ResetPasswordRequest";

import './styles/global.css';


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleLogin = async (email, password) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication_token`, {
                email: email,
                password: password
            });

            // Si l'authentification réussit, gérer la réponse ici
            const token = response.data.token;
            const guid = response.data.data.guid;
            setIsLoggedIn(true);
            localStorage.setItem('authToken', token); // Stockage du token dans localStorage
            localStorage.setItem('userGuid', guid); // Stockage du guid dans localStorage
        } catch (error) {
            // En cas d'erreur lors de la requête
            console.error('Erreur lors de l\'authentification : ', error);
            throw error;
        }
    };

    const handleLogout = () => {
        // Mettre à jour l'état isLoggedIn à false
        setIsLoggedIn(false);
        localStorage.removeItem('authToken');
        localStorage.removeItem('userGuid');
    };

    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                // Token expiré, déconnexion de l'utilisateur
                handleLogout();
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const checkTokenValidity = async () => {
            const authToken = localStorage.getItem('authToken');

            if (authToken) {
                try {
                    // Vérifier la validité du token en effectuant une requête au serveur
                    await axios.get(`${process.env.REACT_APP_API_URL}/validate-token`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    });

                    setIsLoggedIn(true);
                } catch (error) {
                    if (error.response && error.response.status === 401) {
                        // Token expiré, déconnexion de l'utilisateur
                        handleLogout();
                    } else {
                        // Autre erreur, gérer en conséquence
                        console.error('Erreur lors de la validation du token :', error);
                    }
                }
            }

            setIsLoading(false)
        };

        checkTokenValidity();
    }, []);

    if (isLoading) {
        return (
            <div className="app-loader flex-column flex-align-center flex-justify-center">
                <div className="loader"></div>
                <h1>Projets Opacity</h1>
            </div>
        );
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout isLoggedIn={isLoggedIn}/>}>
                    <Route index element={<Homepage/>}/>
                    <Route path="projets" element={<Project/>}/>
                    <Route path="association/">
                        <Route path="a-propos" element={<About/>}/>
                        <Route path="rejoindre" element={<Join/>}/>
                        <Route path="soutenir" element={<Sustain/>}/>
                        <Route path="adherer" element={<Member/>}/>
                    </Route>

                    <Route path="deconnexion"
                           element={isLoggedIn ? <Logout onLogout={handleLogout}/> : <Navigate to="/" replace/>}/>
                    <Route path="profil" element={isLoggedIn ? <Profile/> : <Navigate to="/connexion" replace/>}/>

                    <Route path="mentions-legales" element={<Legal/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="erreur-404" element={<NotFound/>} />
                    <Route path="*" element={<Navigate to="erreur-404" />} />
                </Route>
                <Route path="/" element={<LayoutForms/>}>
                    <Route path="inscription" element={isLoggedIn ? <Navigate to="/" replace/> : <Register/>}/>
                    <Route path="connexion"
                           element={isLoggedIn ? <Navigate to="/profil" replace/> : <Login onLogin={handleLogin}/>}/>
                    <Route path="mot-de-passe-oublie" element={<ResetPasswordRequest/>}/>
                    <Route path="reinitialiser" element={<ResetPassword/>}/>
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
