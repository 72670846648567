import React, {Fragment} from "react";
import {Button} from '@mui/material';

import './project.css';
import Meta from "../../components/Head/Meta";

export default function Project() {
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "name": "Projets de Projets Opacity",
        "description": "Explorez nos projets ! Découvrez nos initiatives innovantes dans le monde des jeux vidéo. Rejoignez-nous dans l'aventure Projets Opacity !",
        "url": "https://www.projets-opacity.fr/projets",
        "hasPart": [
            {
                "@type": "CreativeWork",
                "name": "Arma 3 Fish Your Destiny",
                "description": "Découvrez nos projets : jeu roleplay américain, atmosphère immersive, choix de factions, développement d'influence, expertise de 10 ans !",
                "url": "https://www.projets-opacity.fr/projets"
            },
            {
                "@type": "CreativeWork",
                "name": "Arma 4 RPG",
                "description": "Découvrez notre projets : ARMA 4 RPG !",
                "url": "https://www.projets-opacity.fr/projets"
            }
        ]
    };

    return (
        <>
            <Meta title="Projets - Projets Opacity" jsonLd={jsonLd}
                  description="Explorez nos projets ! Découvrez nos initiatives innovantes dans le monde des jeux vidéo. Rejoignez-nous dans l'aventure Projets Opacity !"/>
            <h1 className="hide-title">Projets de l'association</h1>
            <div className="projects flex-row flex-justify-between">
                <section className="project fyd-project flex-column">
                    <div className="head-project">
                        <div className="logo-project">
                            <img src="/assets/images/logo_fyd_white.png" alt="Logo Arma 3 Project Life"/>
                        </div>
                    </div>
                    <div className="body-project flex-row flex-justify-start flex-wrap">
                        <article className="text-project">
                            <p><strong>Un style de jeu américain 🌎</strong></p>
                            <p>
                                Plongez dans un style de jeu roleplay américain, où les joueurs explorent des décors
                                emblématiques et incarnent des personnages variés représentant la diversité culturelle
                                des États-Unis d’Amérique. Des scénarios réalistes et des interactions riches offrent
                                une immersion totale dans la vie quotidienne à travers le prisme américain.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>Une atmosphère captivante 🤝</strong></p>
                            <p>
                                Plongez dans une atmosphère immersive sur la carte de Fishers Island aux Etats-Unis
                                d’Amérique, où les paysages côtiers et les villages traditionnels offrent un cadre
                                captivant. Découvrez une multitude d'activités, de la pêche à la navigation de
                                plaisance, pour une expérience de jeu captivante et variée.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>Choisissez votre camp</strong></p>
                            <p>
                                Faites face au dilemme en choisissant parmi les factions des Sheriff’s, Fire Rescue,
                                Department of Justice et Civils. Chaque camp propose des missions distinctes et des
                                défis uniques, offrant une immersion totale dans les différents aspects de la vie
                                virtuelle. Choisissez judicieusement pour façonner votre expérience de jeu et contribuer
                                à l'équilibre dynamique de cet univers roleplay.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>Développez votre influence</strong></p>
                            <p>
                                Explorez le jeu de pouvoir en développant votre influence, que ce soit dans le monde des
                                affaires ou au sein du crime organisé. Tissez des alliances, étendez votre emprise et
                                dominez votre domaine pour atteindre le sommet de la hiérarchie. Des négociations
                                politiques aux activités illicites, chaque décision compte dans votre quête de pouvoir
                                et de richesse.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>10 ans de savoir faire</strong></p>
                            <p>
                                Pendant une décennie, notre association a cultivé son expertise en créant un contenu
                                américain authentique, avec une carte dépeignant des paysages emblématiques, des mods et
                                scripts capturant l'essence de la culture américaine, garantissant une expérience de jeu
                                immersive et fidèle à la vie aux États-Unis.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>Créez votre personnage sur FYD !</strong></p>
                            <p>
                                Donnez vie à votre imagination en rejoignant notre serveur de jeu, où vous pouvez créer et
                                personnaliser votre propre personnage. Explorez une carte détaillée, profitez de scripts innovants et
                                plongez dans des mods uniques. Offrez-vous une expérience immersive et unique, où chaque détail
                                contribue à créer un monde virtuel captivant et dynamique. Rejoignez-nous et devenez une partie
                                intégrante de notre communauté de jeu passionnée.

                            </p>
                        </article>
                    </div>
                    <div className="foot-project flex-column flex-justify-center flex-align-center">
                        <Button variant="contained" color="primary"
                                href="http://discord.projets-opacity.fr/"
                                target="_blank">📢 Rejoindre le Discord de FYD !</Button>
                    </div>
                </section>
                <section className="project arma4-project flex-column">
                    <div className="head-project">
                        <div className="logo-project">
                            <img src="/assets/images/logo_a4_white.png" alt="Logo Arma 4 RPG"/>
                        </div>
                    </div>
                    <div className="body-project flex-row flex-justify-start flex-wrap">
                        <article className="text-project">
                            <p><strong>RPG ❓</strong></p>
                            <p>
                                Le jeu de rôle (RPG) sur Arma va au-delà d'un simple gameplay : 
                                il s'agit d'une immersion totale dans un monde où vous pouvez incarner un personnage 
                                unique et vivre des histoires à travers ses yeux. Que vous soyez un civil cherchant à 
                                survivre dans une société réaliste, un membre des forces de l'ordre, ou une figure 
                                politique influente, le RPG vous permet de créer votre propre histoire, entouré 
                                d'autres joueurs, dans un environnement dynamique et ouvert.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>L'Enfusion Engine 🚀​</strong></p>
                            <p>
                                Avec l'arrivée de Arma 4 et Arma Reforger, le moteur Enfusion marque une véritable
                                révolution dans le monde du jeu. Grâce à ce moteur de nouvelle génération, les possibilités 
                                de gameplay et de modding sont poussées à un niveau supérieur. Les graphismes améliorés, 
                                les mécaniques de jeu plus fluides, et une physique plus réaliste offrent une expérience 
                                encore plus immersive et captivante pour les joueurs de RPG.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>Des interactions plus dynamiques et réalistes</strong></p>
                            <p>
                                Grâce aux innovations apportées par Arma 4 et Arma Reforger, les interactions entre 
                                les joueurs et l'environnement sont plus riches que jamais. Que ce soit dans les conversations,
                                les négociations, ou les missions collaboratives, chaque action compte. Le RPG n'a jamais été 
                                aussi réaliste et immersif, permettant aux joueurs d'explorer des histoires personnelles tout 
                                en s'adaptant à un monde vivant qui évolue en fonction de leurs choix.
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>Le projet ?</strong></p>
                            <p>
                                TBA (To Be Annonced)
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>La carte ?</strong></p>
                            <p>
                                TBA (To Be Annonced)
                            </p>
                        </article>
                        <article className="text-project">
                            <p><strong>L'histoire ?</strong></p>
                            <p>
                                TBA (To Be Annonced)
                            </p>
                        </article>
                    </div>
                    <div className="foot-project flex-column flex-justify-center flex-align-center">
                        <Button variant="contained" color="primary"
                                href="#"
                                target="_blank">Date de sortie : TBA</Button>
                    </div>
                </section>
            </div>
        </>
    );
}