import React, {Fragment, useState} from 'react';
import {Link} from 'react-router-dom';
import Meta from "../../components/Head/Meta";
import {Button, IconButton, InputAdornment, InputBase} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import './login.css';

function Login({onLogin}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Connexion",
        "description": "Connectez-vous à votre compte sur Projets Opacity.",
        "url": "https://www.projets-opacity.fr/connexion"
    };

    const handleLoginClick = async () => {
        try {
            await onLogin(email, password);
        } catch (error) {
            setError('Erreur lors de l\'authentification. Veuillez vérifier vos informations.');
            console.error('Erreur lors de l\'authentification : ', error);
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Meta title="Connexion - Projets Opacity" jsonLd={jsonLd}
                  description="Connectez-vous pour un accès exclusif, comme la gestion de votre profil et plus encore. Optimisez votre expérience dès maintenant !"/>
            <section className="login flex-column flex-justify-between flex-align-center">
                <div className="form-login bg-content-primary flex-column flex-align-center flex-justify-start">
                    <div className="title-login flex-column flex-align-center flex-justify-start">
                        <h1>Connexion</h1>
                        <p className="subtext">Connectez-vous pour accéder à l’ensemble des plateformes.</p>
                    </div>

                    <form className="flex-column flex-align-center">
                        <label>
                            Adresse e-mail
                            <InputBase
                                type="email"
                                value={email}
                                placeholder="exemple@projets-opacity.fr"
                                className="input-form" onChange={(e) => setEmail(e.target.value)}
                                autoFocus={true}
                                required={true}
                            />
                        </label>

                        <label>
                            Mot de passe
                            <InputBase
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                placeholder="**********"
                                className="input-form"
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end" className="visibility-form">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required={true}
                            />
                        </label>

                        {error && <p className="error-form">{error}</p>}

                        <Link to="/mot-de-passe-oublie" className="forgot-password-form link-anim">Mot de passe oublié</Link>

                        <Button variant="contained" color="primary" className="submit-form"
                                onClick={handleLoginClick}>Se connecter</Button>
                    </form>
                    <div className="registration-login">
                        <p className="subtext">Vous n'avez pas encore de compte ?</p>
                        <Link to="/inscription" className="link-anim">Créer un compte</Link>
                    </div>
                </div>
                <p>© 2024 Projets Opacity. All Rights Reserved.</p>
            </section>
        </>
    );
}

export default Login;