import React, {Fragment, useEffect, useState} from "react";
import {redirect, useNavigate} from 'react-router-dom';
import axios from "axios";
import {Button} from "@mui/material";

export default function SteamAuth() {
    const [isSteamAuth, setIsSteamAuth] = useState(false);
    const [isLoadingSteam, setIsLoadingSteam] = useState(false);

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const userGuid = localStorage.getItem('userGuid');
    const authToken = localStorage.getItem('authToken');
    const loginUrlParams = {
        'openid.ns': 'http://specs.openid.net/auth/2.0',
        'openid.mode': 'checkid_setup',
        'openid.return_to': `${window.location.protocol}//${window.location.host}/profil`,
        'openid.realm': `${window.location.protocol}//${window.location.host}`,
        'openid.identity': 'http://specs.openid.net/auth/2.0/identifier_select',
        'openid.claimed_id': 'http://specs.openid.net/auth/2.0/identifier_select',
    };
    const loginUrl = 'https://steamcommunity.com/openid/login?' + new URLSearchParams(loginUrlParams);


    const handleUnAuth = async (guid) => {
        if (isSteamAuth) {
            setIsLoadingSteam(false);
            try {
                await axios.post(`${apiUrl}/api/users/${guid}/steam-logout`, {}, {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                });

                // Succès de l'appel à l'API, mettre en place changement d'état du bouton vers dé-authentifier le user
                setIsSteamAuth(false);
                setIsLoadingSteam(true);
            } catch (error) {
                // En cas d'erreur lors de la requête
                console.error('Erreur lors de la suppression de l\'authentification : ', error);
            }
        }
    };

    useEffect(() => {
            async function checkSteamId() {
                if (!isLoadingSteam) {
                    try {
                        // Vérifier la si présence steamid en effectuant une requête au serveur
                        const response = await axios.get(`${apiUrl}/api/users/${userGuid}/steam-id`, {
                            headers: {
                                Authorization: `Bearer ${authToken}`
                            }
                        });

                        if (response.data.steamId !== undefined) {
                            setIsSteamAuth(true);
                        } else {
                            setIsSteamAuth(false);
                        }

                        setIsLoadingSteam(true);

                        redirect(window.location.pathname);
                    } catch (error) {
                        console.error('Erreur lors de la validation du token :', error);
                    }
                }
            }

            checkSteamId();

            const handleAuth = async (params, guid) => {
                if (!isSteamAuth) {
                    setIsLoadingSteam(false);

                    try {
                        await axios.post(`${apiUrl}/api/users/${guid}/steam-login`, {
                            params: params,
                        }, {
                            headers: {
                                Authorization: `Bearer ${authToken}`
                            },
                        }).then(() => {
                            setIsSteamAuth(true);
                        });

                        if (isSteamAuth) {
                            setIsLoadingSteam(true);
                        }

                        navigate('/profil', {replace: true})
                    } catch (error) {
                        // En cas d'erreur lors de la requête
                        console.error('Erreur lors de l\'authentification : ', error);
                    }
                }
            };

            if (isLoadingSteam) {
                // Fonction pour extraire les paramètres de l'URL
                const getQueryParams = () => {
                    const queryParams = new URLSearchParams(window.location.search);
                    const params = {};
                    for (const [key, value] of queryParams.entries()) {
                        params[key] = value;
                    }
                    return params;
                };

                // Vérifier s'il y a des paramètres GET dans l'URL
                const queryParams = getQueryParams();
                if (Object.keys(queryParams).length > 0) {
                    handleAuth(queryParams, userGuid);
                } else {
                    redirect(loginUrl)
                }
            }
        }, [loginUrl, userGuid, apiUrl, authToken, isSteamAuth, isLoadingSteam, navigate]
    )
    ;

    return (
        <>
            {isLoadingSteam ? (
                    <>
                {isSteamAuth ? (
                    <>
                        <p className="steam-auth-message">Votre compte Steam a été lié avec succès. Merci de votre confiance !</p>
                        <Button variant="contained" color="primary" onClick={() => handleUnAuth(userGuid)}>
                            Supprimer la liaison
                        </Button>
                    </>
                ) : (
                    <>
                        <p className="steam-auth-message">Liez votre compte Steam dès maintenant, en cliquant sur le bouton ci-dessous pour
                            commencer le processus de liaison. C'est simple et rapide !</p>
                        <Button variant="contained" color="primary" href={loginUrl}>S'authentifier à Steam</Button>
                    </>
                )}
                    </>
                )
                :
                (
                    <Button variant="contained" color="primary" disabled={true}>Chargement...</Button>
                )
            }
        </>
    )
        ;
}