import React, {Fragment} from "react";
import Meta from "../../components/Head/Meta";

import "./legal.css"

export default function Legal() {

    return (
        <>
            <Meta title="Politique de confidentialité - Projets Opacity"
                  description="Consultez notre politique de confidentialité pour comprendre comment nous protégeons vos informations personnelles. Votre vie privée est importante pour nous."/>
            <section className="legal bg-content-primary flex-column flex-justify-start flex-align-start">
                <h1>Politique de confidentialité</h1>
                <p className="subtitle">
                    Association de loi 1901 enregistrée au registre national des associations au numéro RNA W252007073
                </p>
                <a className="subtitle link-anim" href="https://projets-opacity.fr">https://projets-opacity.fr</a>
                <p className="subtitle">
                    Ce site est un site vitrine.
                </p>
                <h2>Le but de cette politique de confidentialité</h2>
                <p>
                    Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des
                    données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant :
                </p>
                <ul>
                    <li>Les données personnelles que nous recueillerons</li>
                    <li>L’utilisation des données recueillies</li>
                    <li>Qui a accès aux données recueillies</li>
                    <li>Les droits des utilisateurs du site</li>
                    <li>La politique de cookies du site</li>
                </ul>
                <p>
                    Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation
                    de notre site.
                </p>
                <h2>Lois applicables</h2>
                <p>
                    Conformément au <i>Règlement général sur la protection des données</i> (RGPD), cette politique de
                    confidentialité est conforme aux règlements suivants.
                </p>
                <p>
                    Les données à caractère personnel doivent être :
                </p>
                <ul>
                    <li>
                        traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité,
                        loyauté, transparence) ;
                    </li>
                    <li>
                        collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées
                        ultérieurement d'une manière incompatible avec ces finalités; le traitement ultérieur à des fins
                        archivistiques dans l'intérêt public, à des fins de recherche scientifique ou historique ou à
                        des fins statistiques n'est pas considéré, conformément à l'article 89, paragraphe 1, comme
                        incompatible avec les finalités initiales (limitation des finalités) ;
                    </li>
                    <li>
                        adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour
                        lesquelles elles sont traitées
                        (minimisation des données) ;
                    </li>
                    <li>
                        exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises
                        pour que les données à
                        caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont
                        traitées,
                        soient effacées ou
                        rectifiées sans tarder
                        (exactitude) ;
                    </li>
                    <li>
                        conservées sous une forme permettant l'identification des personnes concernées pendant une durée
                        n'excédant pas
                        celle nécessaire au regard des finalités pour lesquelles elles sont traitées; les données à
                        caractère personnel peuvent être
                        conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à
                        des
                        fins archivistiques
                        dans l'intérêt public, à des fins de recherche scientifique ou historique ou à des fins
                        statistiques
                        conformément à l'article
                        89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et
                        organisationnelles
                        appropriées
                        requises par le règlement afin de garantir les droits et libertés de la personne concernée
                        (limitation de la conservation) ;
                    </li>
                    <li>
                        traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y
                        compris
                        la protection contre le
                        traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d'origine
                        accidentelle, à l'aide de
                        mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).
                    </li>
                </ul>
                <p>
                    Le traitement n'est licite que si, et dans la mesure où, au moins une des conditions suivantes est
                    remplie :
                </p>
                <ul>
                    <li>la personne concernée a consenti au traitement de ses données à caractère personnel pour une ou
                        plusieurs finalités
                        spécifiques ;
                    </li>
                    <li>le traitement est nécessaire à l'exécution d'un contrat auquel la personne concernée est partie
                        ou à l'exécution de
                        mesures précontractuelles prises à la demande de celle-ci ;
                    </li>
                    <li>le traitement est nécessaire au respect d'une obligation légale à laquelle le responsable du
                        traitement est soumis ;
                    </li>
                    <li>le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou
                        d'une autre personne
                        physique ;
                    </li>
                    <li>le traitement est nécessaire à l'exécution d'une mission d'intérêt public ou relevant de
                        l'exercice de l'autorité publique
                        dont est investi le responsable du traitement ;
                    </li>
                    <li>le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du
                        traitement ou par un tiers, à
                        moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne
                        concernée qui exigent une
                        protection des données à caractère personnel, notamment lorsque la personne concernée est un
                        enfant.
                    </li>
                </ul>
                <p>
                    Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à
                    la <i>California Consumer Privacy
                    Act (CCPA)</i>. S’il y a des incohérences entre ce document et la <i>CCPA</i>, la législation de
                    l’État s’appliquera. Si nous constatons des
                    incohérences, nous modifierons notre politique pour nous conformer à la loi pertinente.
                </p>
                <h2>Consentement</h2>
                <p>Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :</p>
                <ul>
                    <li>les conditions énoncées dans la présente politique de confidentialité et</li>
                    <li>
                        la collecte, l’utilisation et la conservation des données énumérées dans la présente
                        politique.
                    </li>
                </ul>
                <h2>Données personnelles que nous collectons</h2>
                <h3>Données collectées automatiquement</h3>
                <p>Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les
                    renseignements suivants :</p>
                <ul>
                    <li>Adresse IP</li>
                    <li>Lieu</li>
                    <li>Détails matériels et logiciels</li>
                </ul>
                <h3>Données recueillies de façon non automatique</h3>
                <p>
                    Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions
                    sur notre site :
                </p>
                <ul>
                    <li>Âge</li>
                    <li>Date de naissance</li>
                    <li>Sexe</li>
                    <li>Email, Nom et Prénom</li>
                </ul>
                <p>Ces données peuvent être recueillies au moyen de l'enregistrement d'un compte.</p>
                <p>
                    Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé
                    dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans
                    vous en informer au préalable.
                </p>
                <h2>Comment nous utilisons les données personnelles</h2>
                <p>
                    Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées
                    dans la présente politique ou
                    indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas vos données au-delà de ce
                    que nous divulguerons.
                </p>
                <p>
                    Les données que nous recueillons automatiquement sont utilisées à des fins de statistiques et
                    d'administration.
                </p>
                <p>
                    Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être
                    utilisées à des fins de gestion du site internet.
                </p>
                <h2>Avec qui nous partageons les données personnelles</h2>
                <h3>Employés</h3>
                <p>Nous pouvons divulguer à tout membre de notre association les données utilisateur dont il a
                    raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.</p>
                <h3>Tierces parties</h3>
                <p>Nous pouvons partager les données utilisateur avec les tiers suivants :</p>
                <ul>
                    <li>Les membres de l'équipe de nos serveurs de jeu externe à l'association peuvent avoir accès à ces
                        données.
                    </li>
                </ul>
                <p>Nous pouvons partager les données utilisateur avec des tiers aux fins suivantes :</p>
                <ul>
                    <li>L'ensemble des données collectées lors de l'inscription sur le site internet sont susceptibles
                        d'être communiquées à nos équipes.
                    </li>
                </ul>
                <p>
                    Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est
                    raisonnablement nécessaire pour atteindre l’objectif donné.
                </p>
                <h3>Autres divulgations</h3>
                <p>Nous nous engageons à ne pas vendre ou partager vos données avec d'autres tiers, sauf dans les cas
                    suivants :</p>
                <ul>
                    <li>si la loi l'exige</li>
                    <li>si elle est requise pour toute procédure judiciaire</li>
                    <li>pour prouver ou protéger nos droits légaux</li>
                    <li>à des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la
                        vendre la société
                    </li>
                </ul>
                <p>
                    Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes
                    pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de confidentialité.
                </p>
                <h2>Combien de temps nous stockons les données personnelles</h2>
                <p>Les données des utilisateurs seront conservées pour une durée de dix ans.</p>
                <p>Nous veillerons à ce que les utilisateurs soient avisés si leurs données sont conservées plus
                    longtemps que cette durée.</p>
                <h2>Comment nous protégeons vos données personnelles</h2>
                <p>
                    Afin d'assurer la protection de vos données, les membres des équipes de nos serveurs ainsi que les
                    membres associatifs ayant accès à vos données sont soumis à un accord de confidentialité strict, et
                    une violation de cet accord entraînerait la radiation pour motif grave.
                </p>
                <p>
                    Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données
                    d’utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours du risque de
                    préjudice. L’Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes incapables de
                    garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.
                </p>
                <h2>Transferts internationaux de données</h2>
                <p>
                    Nos membres associatifs et membres de l'équipe n'étant pas exclusivement résidents français, vos
                    données peuvent être recueillies, traitées ou consultées dans le monde entier.
                </p>
                <h2>Mineurs</h2>
                <p>
                    Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de
                    la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que
                    leurs données soient recueillies, traitées et utilisées.
                </p>
                <p>
                    Nous n’avons pas l’intention de recueillir ou d’utiliser des données auprès d’utilisateurs mineurs.
                    Si nous découvrons que nous avons recueilli des données d’un mineur, ces données seront supprimées
                    immédiatement.
                </p>
                <h2>Vos droits en tant qu’utilisateur</h2>
                <p>
                    En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :
                </p>
                <ul>
                    <li>droit d’accès</li>
                    <li>droit de rectification</li>
                    <li>droit à l’effacement</li>
                    <li>droit de restreindre le traitement</li>
                    <li>droit à la portabilité des données</li>
                    <li>droit d'objection</li>
                </ul>
                <p>Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD.</p>
                <h2>Comment modifier, supprimer ou contester les données recueillies</h2>
                <p>
                    Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre,
                    veuillez communiquer avec notre agent de protection de la vie privée ici :
                </p>
                <p className="tab-legal">PROJETS OPACITY - Kévin COMBAR</p>
                <p className="tab-legal">58 rue de Monceau, 75008 Paris, France</p>
                <p className="tab-legal">
                    <a href="mailto:ca@projets-opacity.fr" className="link-anim">ca@projets-opacity.fr</a>
                </p>
                <h2>Politique sur les cookies</h2>
                <p>
                    Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but
                    est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.
                </p>
                <p>Nous utilisons les types de cookies suivants sur notre site :</p>
                <ul>
                    <li>
                        Cookies fonctionnels
                        Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin
                        qu’elles soient sauvegardées pour vos prochaines visites.
                    </li>
                </ul>
                <p>
                    Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également
                    choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut
                    diminuer la qualité de votre expérience d’utilisation.
                </p>
                <h2>Modifications</h2>
                <p>
                    Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité
                    avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous
                    recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils
                    soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel
                    des changements apportés à cette politique.
                </p>
                <h2>Contact</h2>
                <p>
                    Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui
                    suit :
                </p>
                <p className="tab-legal">
                    <a href="mailto:hello@projets-opacity.fr" className="link-anim">hello@projets-opacity.fr</a>
                </p>
                <p className="tab-legal">58 rue de Monceau, 75008 Paris, France</p>
                <p className="tab-legal">SIREN : 930088620</p>
                <p className="tab-legal">SIRET : 93008862000016</p>
                <p className="tab-legal">RNA : W751274676</p>
                <p className="last-legal"><i>Date d’entrée en vigueur :</i> le 10 juillet 2024</p>
            </section>
        </>
    );
}