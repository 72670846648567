import React from "react";
import {Button} from '@mui/material';
import Meta from "../../components/Head/Meta";

import "./notFound.css";

export default function NotFound() {

    return (
        <>
            <Meta title="Page non trouvée - Projets Opacity"/>
            <div className="not-found flex-column flex-align-center flex-justify-start">
                <img className="logo" src="/assets/images/svg/po_logo_white.svg" alt="Logo Projets Opacity"/>
                <div className="text flex-column">
                    <h1>Page non trouvée 😥</h1>
                    <p>Désolé, la page que vous recherchez n'existe pas.</p>
                </div>
                <div className="buttons flex-row flex-align-start flex-justify-center">
                    <Button variant="contained" color="secondary" href={'/association/a-propos'}>À propos de
                        l'association</Button>
                    <Button variant="contained" color="primary" href={'/projets'}>Découvrir nos projets</Button>
                </div>
            </div>
        </>
    );
}