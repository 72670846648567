import React from "react";
import {Outlet} from "react-router-dom";
import {Container} from '@mui/material';

import BackHome from "../Header/BackHome";
import PoImage from "../PoImage/PoImage";

function LayoutForms() {
    return (
        <>
            <main>
                <Container maxWidth="xl">
                    <BackHome/>
                    <div className="content">
                        <Outlet/>
                        <PoImage/>
                    </div>
                </Container>
            </main>
        </>
    )
        ;
}

export default LayoutForms;