import React from "react";
import {Link} from 'react-router-dom';

import './backHome.css';

function BackHome() {
    return (
        <>
            <div className="back-home">
                <Link to="/" className="stretch-link link-anim flex-row flex-justify-start flex-align-center">
                    <img src="/assets/images/svg/back.svg" alt="Icon flèche retour"/>
                    Retour à l'accueil
                </Link>
            </div>
        </>
    );
}

export default BackHome;