import React, {useState} from "react";
import {Link} from 'react-router-dom';

import './menu.css';

function Menu({ isLoggedIn }) {
    const [menuHamburger, setMenuHamburger] = useState(false);

    const handleClick = () => {
        setMenuHamburger(!menuHamburger);
    };

    const closeMenu = () => {
        setMenuHamburger(false);
    };

    return (
        <nav>
            <ul className="menu-po">
                <li>
                    <Link to="/" className="stretch-link">
                        <img src="/assets/images/svg/home.svg" alt="Icon home"/>
                        Accueil
                    </Link>
                </li>
                <span></span>
                <li>
                    <Link to="/projets" className="stretch-link">
                        <img src="/assets/images/svg/dashboard.svg" alt="Icon dashboard"/>
                        Projets
                    </Link>
                </li>
                <span></span>
                <li>
                    <img src="/assets/images/svg/info.svg" alt="Icon info"/>
                    Association
                    <div className="sub-menu-po">
                        <ul>
                            <li><Link to="/association/a-propos" className="stretch-link">À propos</Link></li>
                            <li><Link to="/association/rejoindre" className="stretch-link">Rejoindre</Link></li>
                            <li><Link to="/association/soutenir" className="stretch-link">Soutenir</Link></li>
                            <li><Link to="/association/adherer" className="stretch-link">Adhérer</Link>
                            </li>
                        </ul>
                    </div>
                </li>
                <span></span>
                <li>
                    <Link to="https://forum.projets-opacity.fr/" target="_blank" className="stretch-link disable-link">
                        <img src="/assets/images/svg/toolbar.svg" alt="Icon toolbar"/>
                        Forum
                    </Link>
                </li>
            </ul>
            <div className="menu-mobile-po">
                <button onClick={handleClick}
                        className={menuHamburger ? 'button-hamburger active-button' : 'button-hamburger'}>
                    <span></span>
                </button>
                <div className={menuHamburger ? 'menu-hamburger active-menu' : 'menu-hamburger'}>
                    <ul>
                        <li>
                            <Link to="/" className="stretch-link" onClick={closeMenu}>
                                <img src="/assets/images/svg/home.svg" alt="Icon home"/>
                                Accueil
                            </Link>
                        </li>
                        <li>
                            <Link to="/projets" className="stretch-link" onClick={closeMenu}>
                                <img src="/assets/images/svg/dashboard.svg" alt="Icon dashboard"/>
                                Projets
                            </Link>
                        </li>
                        <li className="sub-menu-po">
                            <div>
                                <img src="/assets/images/svg/info.svg" alt="Icon info"/>
                                Association
                            </div>
                            <ul>
                                <li>
                                    <Link to="/association/a-propos" className="stretch-link" onClick={closeMenu}>À
                                        propos</Link>
                                </li>
                                <li>
                                    <Link to="/association/rejoindre" className="stretch-link"
                                          onClick={closeMenu}>Rejoindre</Link>
                                </li>
                                <li>
                                    <Link to="/association/soutenir" className="stretch-link"
                                          onClick={closeMenu}>Soutenir</Link>
                                </li>
                                <li>
                                    <Link to="/association/adherer" className="stretch-link" onClick={closeMenu}>Adhérer</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="https://forum.projets-opacity.fr/" target="_blank" className="stretch-link disable-link" /*onClick={closeMenu}*/>
                                <img src="/assets/images/svg/toolbar.svg" alt="Icon toolbar"/>
                                Forum
                            </Link>
                        </li>
                        {isLoggedIn ? (
                            <>
                                <li>
                                    <Link to="/profil" className="stretch-link"
                                          onClick={closeMenu}>
                                        <img src="/assets/images/svg/profile.svg" alt="Icon profile"/>
                                        Profil
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/deconnexion" className="stretch-link"
                                          onClick={closeMenu}>
                                        <img src="/assets/images/svg/logout.svg" alt="Icon logout"/>
                                        Déconnexion
                                    </Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li>
                                    <Link to="/connexion" className="stretch-link"
                                          onClick={closeMenu}>
                                        <img src="/assets/images/svg/login.svg" alt="Icon info"/>
                                        Connexion
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Menu;