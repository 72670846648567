import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import axios from "axios";

import Menu from "./Menu";

import './header.css';

function Header({isLoggedIn}) {
    const [userInfo, setUserInfo] = useState([]);

    const userGuid = localStorage.getItem('userGuid');
    const authToken = localStorage.getItem('authToken');

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (authToken) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userGuid}`, {
                        headers: {
                            Authorization: `Bearer ${authToken}`
                        }
                    });

                    setUserInfo(response.data);

                    if (response.data.firstname && response.data.lastname) {
                        const userName = response.data.firstname + ' ' + response.data.lastname[0] + '.' + (response.data.pseudo && ' (' + response.data.pseudo + ')');

                        localStorage.setItem('userName', userName);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchUserInfo();
    }, [authToken, userGuid]);

    return (
        <header>
            <div className="logo-po">
                <Link to="/">
                    <img src="/assets/images/svg/po_logo_white.svg" alt="Logo Projets Opacity"/>
                </Link>
            </div>
            <Menu isLoggedIn={isLoggedIn}/>
            <div className="auth-po">
                {isLoggedIn ? (
                    <>
                        <div className="profil-po flex-row flex-align-center">
                            <img src="/assets/images/svg/profile.svg" alt="Icon profile"/>
                            <Link to="/profil"  title="Mon profil"
                                  className="stretch-link">{userInfo.firstname && userInfo.lastname ? userInfo.firstname + ' ' + userInfo.lastname[0] + '.' : 'Profil'}</Link>
                        </div>
                        <span></span>
                        <div className="logout-po">
                            <Link to="/deconnexion" className="stretch-link" title="Se déconnecter">
                                <img src="/assets/images/svg/logout.svg" alt="Icon logout"/>
                            </Link>
                        </div>
                    </>
                ) : (
                    <div className="login-po flex-row flex-align-center">
                        <img src="/assets/images/svg/login.svg" alt="Icon info"/>
                        <Link to="/connexion" className="stretch-link">Connexion</Link>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;