import React, {useState} from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import {Button, IconButton, InputAdornment, InputBase} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import AlertDialog from "../../components/Dialog/AlertDialog";
import Meta from "../../components/Head/Meta";


function ResetPassword() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage(false);

        // Vérifier que les mots de passe correspondent
        if (password !== confirmPassword) {
            setErrorMessage('Les mots de passe ne correspondent pas');
            return;
        }

        // Récupérer le token de l'URL
        const token = new URLSearchParams(window.location.search).get('token');

        // Appeler l'API pour réinitialiser le mot de passe
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/password-reset`, {token, password});
            setSuccessMessage(true);
        } catch (error) {
            setErrorMessage('Erreur lors de la réinitialisation du mot de passe');
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Meta title="Réinitialisation du mot de passe - Projets Opacity"/>
            <section className="login flex-column flex-justify-between flex-align-center">
                <div className="form-login bg-content-primary flex-column flex-align-center flex-justify-start">
                    <div className="title-login flex-column flex-align-center flex-justify-start">
                        <h1>Réinitialisation du mot de passe</h1>
                        <p className="subtext">Renseignez votre nouveau mot de passe.</p>
                    </div>

                    <form onSubmit={handleSubmit} className="flex-column flex-align-center">
                        <label>
                            Nouveau mot de passe
                            <InputBase
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                placeholder="**********"
                                className="input-form"
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end" className="visibility-form">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                autoFocus={true}
                                required={true}
                            />
                        </label>
                        <label>
                            Confirmer le nouveau mot de passe
                            <InputBase
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                placeholder="**********"
                                className="input-form"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end" className="visibility-form">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required={true}
                            />
                        </label>

                        {errorMessage && <p className="error-form">{errorMessage}</p>}

                        <Button type="submit" variant="contained" color="primary" className="submit-form">
                            Réinitialiser le mot de passe
                        </Button>
                    </form>

                    <div className="registration-login">
                        <p className="subtext">Vous avez déjà un compte ?</p>
                        <Link to="/connexion" className="link-anim">Se connecter</Link>
                    </div>
                </div>
                <p>© 2024 Projets Opacity. All Rights Reserved.</p>
            </section>

            {successMessage && (
                <AlertDialog
                    title="Mot de passe modifié"
                    description="Votre mot de passe a été réinitialisé avec succès."
                    isOpen={true}
                    link={{"name": "Se connecter", "url": "/connexion"}}
                />
            )}
        </>
    );
}

export default ResetPassword;