import React from "react";
import {Helmet, HelmetProvider} from 'react-helmet-async';

function Meta({title, description, jsonLd}) {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title ? title : 'Projets Opacity'}</title>
                <meta name="description"
                      content={description ? description : 'Bienvenue sur Projets Opacity ! L\'association rassemble les passionnés de jeux vidéo et de développement. Découvrez notre univers dès aujourd\'hui !'}/>
                <meta name="keywords"
                      content="jeux vidéo, développement, association, Projets Opacity, communauté, projets, passionnés, innovation"/>

                <link rel="canonical" href={window.location.href}/>

                <meta property="og:locale" content="fr_FRANCE"/>
                <meta property="og:title"
                      content={title ? title : 'Projets Opacity'}/>
                <meta property="og:description"
                      content={description ? description : 'Bienvenue sur Projets Opacity ! L\'association rassemble les passionnés de jeux vidéo et de développement. Découvrez notre univers dès aujourd\'hui !'}/>
                <meta property="og:image" content="https://www.projets-opacity.fr/assets/images/po_logo.jpg"/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:type" content="website"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title"
                      content={title ? title : 'Projets Opacity'}/>
                <meta name="twitter:description"
                      content={description ? description : 'Bienvenue sur Projets Opacity ! L\'association rassemble les passionnés de jeux vidéo et de développement. Découvrez notre univers dès aujourd\'hui !'}/>
                <meta name="twitter:image" content="https://www.projets-opacity.fr/assets/images/po_logo.jpg"/>

                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "WebSite",
                            "name": "Projets Opacity",
                            "url": "https://www.projets-opacity.fr",
                            "potentialAction": [
                                {
                                    "@type": "RegisterAction",
                                    "target": "https://www.projets-opacity.fr/inscription"
                                },
                                {
                                    "@type": "LoginAction",
                                    "target": "https://www.projets-opacity.fr/connexion"
                                }
                            ],
                            "sameAs": [
                                "http://discord.projets-opacity.fr/"
                            ]
                        }
                    )}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify(jsonLd)}
                </script>
            </Helmet>
        </HelmetProvider>
    );
}

export default Meta;