import React, {Fragment} from "react";
import {Button} from '@mui/material';

import './member.css';
import Meta from "../../components/Head/Meta";

export default function Member() {
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Adhérer à Projets Opacity",
        "description": "Devenez membre d'une communauté passionnée de jeux vidéo et d'environnements multijoueurs. Accès exclusif, projets créatifs, soutien d'une cause non lucrative.",
        "url": "https://www.projets-opacity.fr/association/adherer",
        "mainEntity": {
            "@type": "HowTo",
            "name": "Comment Adhérer",
            "description": "Suivez ces étapes simples pour devenir membre de notre association.",
            "step": [
                {
                    "@type": "HowToStep",
                    "name": "1. Visitez notre site internet",
                    "url": "https://www.projets-opacity.fr/"
                },
                {
                    "@type": "HowToStep",
                    "name": "2. Rendez-vous sur notre page HelloAsso",
                    "url": "https://www.helloasso.com/associations/projets-opacity"
                },
                {
                    "@type": "HowToStep",
                    "name": "3. Soumettez votre adhésion sur HelloAsso",
                    "url": "https://www.helloasso.com/associations/projets-opacity/adhesions/membre-sympathisant"
                }
            ]
        }
    };

    return (
        <>
            <Meta title="Adhérer - Projets Opacity" jsonLd={jsonLd}
                  description="Devenez membre d'une communauté passionnée de jeux vidéo et d'environnements multijoueurs. Accès exclusif, projets créatifs, soutien d'une cause non lucrative."/>
            <section className="member flex-column flex-justify-start flex-align-start">
                <div className="title-member bg-content-primary flex-column flex-justify-start">
                    <h1>Adhérer</h1>
                    <p className="subtitle">
                        Adhérer à l'association Projets Opacity, c'est rejoindre une communauté passionnée par les
                        jeux vidéo et les environnements multijoueurs innovants. En tant que membre, vous aurez accès à
                        des ressources exclusives, participerez à des projets créatifs, et contribuerez activement à des
                        initiatives passionnantes. Rejoignez-nous pour soutenir une cause non lucrative qui enrichit
                        l'expérience de jeu pour tous.
                    </p>
                    <div className="button-member flex-column flex-align-center flex-justify-center">
                        <Button variant="contained" color="secondary" href={'/association/a-propos'}>En savoir plus
                            sur l'association</Button>
                    </div>
                </div>
                <div className="widget-member bg-content-primary flex-column flex-justify-start">
                    <iframe title="Formulaire d'adhesion à l'association"
                            src="https://www.helloasso.com/associations/projets-opacity/adhesions/membre-sympathisant/widget"
                            height="100%" width="100%"></iframe>
                </div>
            </section>
        </>
    );
}