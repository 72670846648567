import React, {Fragment, useRef, useEffect, useState} from "react";
import {Button, InputBase, Tab, Tabs, TextField} from "@mui/material";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

import Meta from "../../components/Head/Meta";
import ContentTabs from "../../components/ContentTabs/ContentTabs";
import AlertDialog from "../../components/Dialog/AlertDialog";

import './join.css';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Join() {
    const [viewJob, setViewJob] = useState(0);
    const [jobs, setJobs] = useState(null);

    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [pseudo, setPseudo] = useState('');
    const [experience, setExperience] = useState('');
    const [motivation, setMotivation] = useState('');
    const [skill, setSkill] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const recaptcha = useRef();
    const state = 0;

    const userGuid = localStorage.getItem('userGuid');

    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "JoinAction",
        "name": "Rejoindre l'association",
        "description": "Rejoignez-nous, contribuez à nos projets, découvrez les opportunités de bénévolat et les postes disponibles. Faites la différence !",
        "url": "https://www.projets-opacity.fr/association/rejoindre",
        "potentialAction": {
            "@type": "RegisterAction",
            "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.projets-opacity.fr/association/rejoindre",
                "actionPlatform": [
                    "http://schema.org/DesktopWebPlatform",
                    "http://schema.org/MobileWebPlatform"
                ],
                "httpMethod": "GET"
            }
        }
    };

    const handleSubmit = async (e, job) => {
        e.preventDefault();
        const captchaValue = recaptcha.current.getValue()

        
        setLoading(true);
        setSuccess(false);
        setError(null);

        if (experience.length < 10 || motivation.length < 10 || skill.length < 10) {
            setError("Les champs Expériences précédentes, Motivations et Compétences doivent avoir au moins 10 caractères.");
            setLoading(false);
            return;
        }

        const data = {
            email,
            firstname,
            lastname,
            pseudo,
            experience,
            motivation,
            skill,
            state,
            job,
            captchaValue
        };

        if (userGuid) {
            data.user = userGuid;
        } else {
            data.user = null
        }

        if (!captchaValue) {
            setError('Erreur avec le reCAPTCHA');
            setLoading(false);
        } else {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/recruits`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/ld+json',
                    },
                    body: JSON.stringify(data),
                });

                if (!response.ok) {
                    setError("Une erreur est survenue lors de l'enregistrement.");
                    return;
                } else {
                    setSuccess(true);
                }

                // Réinitialiser les champs du formulaire après un enregistrement réussie
                setEmail('');
                setFirstname('');
                setLastname('');
                setPseudo('');
                setExperience('');
                setMotivation('');
                setSkill('');
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleChangeJob = (event, newValue) => {
        setViewJob(newValue);
    };

    useEffect(() => {
        const fetchAllJobs = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

                setJobs(response.data['hydra:member']);
            } catch (error) {
                console.error(error);
            }
        };

        fetchAllJobs();
    }, []);

    return (
        <>
            <Meta title="Rejoindre - Projets Opacity" jsonLd={jsonLd}
                  description="Rejoignez-nous, contribuez à nos projets, découvrez les opportunités de bénévolat et les postes disponibles. Faites la différence !"/>
            <section className="join flex-column flex-justify-start flex-align-start">
                <div className="title-join bg-content-primary flex-column flex-justify-start">
                    <h1>Rejoindre l'association</h1>
                    <p className="subtitle">
                        Bien souvent les joueurs veulent participer à une aventure humaine dans le jeu vidéo, mais
                        n'osent pas franchir le pas.
                    </p>
                    <p className="subtitle">
                        C'est pourquoi Projets Opacity vous donne une chance de vous investir dans des
                        projets solides et
                        ambitieux avec une ambiance chaleureuse et encadrés par l'association.
                    </p>
                    <p className="subtitle">
                        Nous nous adaptons aux vies de nos membres pour mener nos idées de l'avant en profitant du
                        meilleur de chacun.
                    </p>
                </div>
                {jobs ? (
                    <>
                        <div className="jobs-join bg-content-primary flex-column flex-justify-start">
                            <div className="infos-job">
                                <Tabs className="tabs-job" value={viewJob} onChange={handleChangeJob}
                                      aria-label="Sélecteur de position" variant="scrollable" scrollButtons="auto">
                                    {jobs.map((item, index) => (
                                        <Tab label={item.name} {...a11yProps(index)} key={index}
                                             className={item.name === "Spontanée" ? 'last-tab-job' : ''}/>
                                    ))}
                                </Tabs>
                                {jobs.map((item, index) => (
                                    <ContentTabs value={viewJob} index={index} key={index}>
                                        <div className="content-job flex-column">
                                            <h2>{item.name}</h2>
                                            <div
                                                className="texts-job flex-row flex-justify-start flex-align-start flex-wrap">
                                                {item.description ? (
                                                    <div className="text-job">
                                                        <h3>Description de la position</h3>
                                                        <p>{item.description}</p>
                                                    </div>
                                                ) : ''}
                                                {item.profile ? (
                                                    <div className="text-job">
                                                        <h3>Profil recherché pour la position</h3>
                                                        <p>{item.profile}</p>
                                                    </div>
                                                ) : ''}
                                                {item.mission ? (
                                                    <div className="text-job">
                                                        <h3>Missions de la position</h3>
                                                        <p>{item.mission}</p>
                                                    </div>
                                                ) : ''}
                                                {item.bring ? (
                                                    <div className="text-job">
                                                        <h3>Ce que Projets Opacity peut vous apporter</h3>
                                                        <p>{item.bring}</p>
                                                    </div>
                                                ) : ''}
                                                {item.develop ? (
                                                    <div className="text-job">
                                                        <h3>Développement de la position</h3>
                                                        <p>{item.develop}</p>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </ContentTabs>
                                ))}
                            </div>
                        </div>
                        <div className="form-join bg-content-primary flex-column flex-justify-start">
                            {jobs.map((item, index) => (
                                <ContentTabs value={viewJob} index={index} key={index}>
                                    <div className="form-job flex-column">
                                        <h2>Candidater à la position de "{item.name}"</h2>

                                        <form className="flex-column flex-align-center"
                                              onSubmit={(e) => handleSubmit(e, item['GUID'])}>
                                            <div className="row-form">
                                                <div className="col-form">
                                                    <label>
                                                        Position visée*
                                                        <InputBase
                                                            type="text"
                                                            value={item.name}
                                                            placeholder="Nom de la position"
                                                            className="input-form"
                                                            required={true}
                                                            disabled={true}
                                                        />
                                                    </label>
                                                    <label>
                                                        Expérences précédentes*
                                                        <TextField
                                                            multiline
                                                            rows={3}
                                                            value={experience}
                                                            placeholder="Vos expériences précédantes"
                                                            className="textarea-form"
                                                            onChange={(e) => setExperience(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                    <label>
                                                        Motivations*
                                                        <TextField
                                                            multiline
                                                            rows={3}
                                                            value={motivation}
                                                            placeholder="Vos motivations"
                                                            className="textarea-form"
                                                            onChange={(e) => setMotivation(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                    <label>
                                                        Compétences*
                                                        <TextField
                                                            multiline
                                                            rows={3}
                                                            value={skill}
                                                            placeholder="Vos compétences"
                                                            className="textarea-form"
                                                            onChange={(e) => setSkill(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                </div>

                                                <div className="right-form-join flex-row flex-justify-start flex-wrap">
                                                    <label>
                                                        Prénom*
                                                        <InputBase
                                                            type="text"
                                                            value={firstname}
                                                            placeholder="John"
                                                            className="input-form"
                                                            onChange={(e) => setFirstname(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                    <label>
                                                        Nom*
                                                        <InputBase
                                                            type="text"
                                                            value={lastname}
                                                            placeholder="Doe"
                                                            className="input-form"
                                                            onChange={(e) => setLastname(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                    <label>
                                                        Adresse e-mail*
                                                        <InputBase
                                                            type="email"
                                                            value={email}
                                                            placeholder="exemple@projets-opacity.fr"
                                                            className="input-form"
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                    <label>
                                                        Pseudo Discord*
                                                        <InputBase
                                                            type="text"
                                                            value={pseudo}
                                                            placeholder="Joe"
                                                            className="input-form"
                                                            onChange={(e) => setPseudo(e.target.value)}
                                                            required={true}
                                                        />
                                                    </label>
                                                </div>
                                            </div>

                                            <ReCAPTCHA
                                            ref={recaptcha}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            />

                                            {error && <p className="error-form">{error}</p>}

                                            <Button type="submit" variant="contained" color="primary"
                                                    className="submit-form"
                                                    disabled={loading}>{loading ? 'Envoie en cours...' : 'Envoyer ma candidature'}</Button>
                                            <p className="subtext">* Obligatoire</p>
                                        </form>
                                    </div>
                                </ContentTabs>
                            ))}
                        </div>

                        {success && (
                            <AlertDialog
                                title="Candidature envoyée"
                                description="Votre candidature a bien été envoyé à l'association Projets Opacity."
                                isOpen={true}
                            />
                        )}
                    </>
                ) : (
                    <div className="jobs-join bg-content-primary flex-column flex-justify-center flex-align-center">
                        <div className="loader"></div>
                    </div>
                )}
            </section>
        </>
    );
}