import React from "react";
import {Link} from 'react-router-dom';

import './footer.css';

function Footer() {
    return (
        <footer>
            <div className="liens flex-row flex-align-center flex-justify-center">
                <Link to="/contact" className="link-anim">Contact</Link>
                <Link to="/mentions-legales" className="link-anim">Mentions légales</Link>
                <p>© 2024 Projets Opacity. All Rights Reserved.</p>
                <Link to="https://status.projets-opacity.fr/" target="_blank" className="link-anim disable-link">
                    Status
                    <img src="/assets/images/svg/open_in_new.svg" alt="Icône ouvrir dans un nouvel onglet"/>
                </Link>
                <Link to="http://discord.projets-opacity.fr/" target="_blank" className="link-anim">
                    Discord
                    <img src="/assets/images/svg/open_in_new.svg" alt="Icône ouvrir dans un nouvel onglet"/>
                </Link>
            </div>
        </footer>
    );
}

export default Footer;