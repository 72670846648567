import React, {useEffect, useState, Fragment} from "react";
import axios from "axios";
import {Button} from '@mui/material';

import './about.css';
import Meta from "../../components/Head/Meta";

export default function About() {
    const [allMembers, setAllMembers] = useState([]);

    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Projets Opacity",
        "url": "https://www.projets-opacity.fr/association/a-propos",
        "logo": "https://www.projets-opacity.fr/logo512.png",
        "description": "Bienvenue sur Projets Opacity ! L'association rassemble les passionnés de jeux vidéo et de développement. Découvrez notre univers dès aujourd'hui !"
    };

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/members`)
            .then((response) => {
                setAllMembers(response.data.members);
            })
            .catch((error) => {
                console.error("Error fetching all members:", error);
            });
    }, []);

    return (
        <>
            <Meta title="À propos - Projets Opacity" jsonLd={jsonLd}
                  description="Découvrez Projets Opacity, une association de passionnés de jeux vidéo et de développement, dédiée à l'innovation et à la création de communautés dynamiques."/>
            <section className="about flex-column flex-justify-start flex-align-start">
                <div className="title-about bg-content-primary flex-column flex-justify-start">
                    <h1>À propos de l'association</h1>
                    <p className="subtitle">
                        Projets Opacity est une association déclarée à la préfecture en 2023. Elle a pour but de
                        promouvoir le
                        jeu de rôle en ligne auprès du plus grand nombre et de rassembler des communautés autour d’un
                        intérêt commun.
                    </p>
                    <p className="subtitle">
                        Pour cela, nous avons comme ambition la création et la modification d’environnement de jeu vidéo
                        en
                        multijoueur sur la plateforme PC destinée aux joueurs, gratuitement.
                    </p>
                </div>

                <div className="texts-about">
                    <article className="text-about bg-content-primary">
                        <h2>Comment l'association est financée et comment l'argent est utilisé ?</h2>
                        <p>L’association est financée par les cotisations de ses membres et les donations.</p>
                        <p>L’argent est utilisé uniquement pour les objectifs de l’association. Ainsi tout
                            enrichissement
                            personnel est prohibé par le simple fait que nous sommes une association à but non
                            lucratif.</p>
                    </article>
                    <article className="text-about bg-content-primary">
                        <h2>Pourquoi rentrer dans notre association ?</h2>
                        <p>C’est un formidable outil social qui permet de mettre en commun des connaissances pour
                            réaliser
                            un projet collectif qui bénéficie autant pour ses membres que pour autrui. Elle permet de
                            faire
                            des rencontres.</p>
                        <p>La transmission des connaissances et la bienveillance des membres rend possible à chacun
                            d’acquérir des compétences à titre personnel ou professionnel.</p>
                        <p>Rentrer dans une association c’est un nivellement constant par le haut.</p>
                        <p>Elle vous donne aussi une structure juridique qui est là pour vous encadrer et vous
                            protéger.</p>
                        <div className="button-about flex-column flex-align-center flex-justify-center">
                            <Button variant="contained" color="secondary" href={'/association/rejoindre'}>En savoir plus
                                sur les recrutements</Button>
                        </div>
                    </article>
                    <article className="text-about bg-content-primary">
                        <h2>Comment travaillons-nous ?</h2>
                        <p>Tout ce qui touche à l’aspect administratif, légal et financier est géré par le conseil
                            d’administration, élu pour une durée d'un an.</p>
                        <p>Les membres ont un droit de regard sur chaque démarche. De plus, chaque année lors de
                            l’assemblée générale, tous les membres sont invités à discuter et à voter les directives
                            majeures de l’association.</p>
                        <p>Pour le reste, nous fonctionnons sous forme de projet. Chaque projet part d’une idée, d’une
                            vision partagée par le plus grand nombre. Il est ensuite réfléchi pour qu’il soit utile et
                            s’inscrive dans la démarche de l’association.</p>
                        <p>Un chef de projet émerge et prend en charge sa direction en rassemblant les effectifs dont il
                            a besoin. Les participants au projet se voient assigner des tâches en accord avec leur temps
                            de disponibilité. Le projet se construit brique par brique.</p>
                    </article>
                    <article className="text-about bg-content-primary">
                        <h2>Que vous demande en retour "Projets Opacity" ?</h2>
                        <p>Un intéressement aux différents projets de l’association. Une participation dans son
                            évolution.
                            Une activité régulière. De l’initiative. De la bonne humeur.</p>
                    </article>
                </div>

                <div className="team-about bg-content-primary flex-column">
                    <h2>Membres de l'association</h2>
                    <div className="members-about flex-row flex-wrap flex-justify-start">
                        {allMembers.map((member, index) => (
                            <div key={index} className="member-about flex-column flex-justify-start flex-align-center">
                                <div className="avatar-member">
                                    {member.avatar ? (
                                        <img src={member.avatar} alt={"Photo de profil Steam de "+member.pseudo}/>
                                    ) : (
                                        // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                        <img src="/assets/images/image_remplacement_po.jpg"
                                             alt="Photo de profil par défaut"/>
                                    )}
                                </div>
                                <p className="name-member"><strong>
                                    {member.firstname && member.lastname && member.pseudo ? (
                                        <>{member.firstname + ' ' + member.lastname + ' (' + member.pseudo + ')'}</>
                                    ) : (
                                        <>{member.pseudo}</>
                                    )}
                                </strong></p>
                                {member.professions ? member.professions.map((profession, index) => (
                                    <p key={index} className="subtext job-member">{profession}</p>
                                )) : ''}
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}