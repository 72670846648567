import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

import './PoImage.css';

function poImage() {
    return (
        <>
            <section className="po-image">
                <div className="container-po-image flex-column flex-justify-between flex-align-center">
                    <img className="logo-po-image" src="/assets/images/svg/po_logo_white.svg" alt="Logo Projets Opacity"/>
                    <footer>
                        <div className="liens flex-row flex-align-center flex-justify-center">
                            <Link to="/contact" className="link-anim">Contact</Link>
                            <Link to="/mentions-legales" className="link-anim">Mentions légales</Link>
                            <Link to="https://status.projets-opacity.fr/" target="_blank" className="link-anim disable-link">
                                Status
                                <img src="/assets/images/svg/open_in_new.svg" alt="Icône ouvrir dans un nouvel onglet"/>
                            </Link>
                            <Link to="http://discord.projets-opacity.fr/" target="_blank" className="link-anim">
                                Discord
                                <img src="/assets/images/svg/open_in_new.svg" alt="Icône ouvrir dans un nouvel onglet"/>
                            </Link>
                        </div>
                    </footer>
                </div>
            </section>
        </>
    );
}

export default poImage;