import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Button, Checkbox, IconButton, InputAdornment, InputBase} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import './register.css';
import Meta from "../../components/Head/Meta";

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [pseudo, setPseudo] = useState('');
    const [newsletter, setNewsletter] = useState(false);
    const [showName, setShowName] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Inscription",
        "description": "Inscrivez-vous pour devenir membre sur Projets Opacity.",
        "url": "https://www.projets-opacity.fr/inscription"
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas.');
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    firstname,
                    lastname,
                    pseudo,
                    newsletter,
                    showName,
                }),
            });

            if (!response.ok) {
                setError("Une erreur est survenue lors de l'inscription.");
                return;
            } else {
                // Rediriger vers la page de connexion après une inscription réussie
                navigate("/connexion");
            }

            // Réinitialiser les champs du formulaire après une inscription réussie
            setEmail('');
            setPassword('');
            setFirstname('');
            setLastname('');
            setPseudo('');
            setNewsletter(false);
            setShowName(false);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Meta title="Inscription - Projets Opacity" jsonLd={jsonLd}
                  description="Inscrivez-vous dès maintenant ! Rejoignez notre communauté et profitez d'avantages exclusifs. Une inscription simplifiée en quelques clics seulement."/>
            <section className="register flex-column flex-justify-between flex-align-center">
                <div className="form-register bg-content-primary flex-column flex-align-center flex-justify-start">
                    <div className="title-register flex-column flex-align-center flex-justify-start">
                        <h1>Inscription</h1>
                        <p className="subtext">Pour accéder à l’ensemble des plateformes, vous devez créer votre compte
                            utilisateur.</p>
                    </div>

                    <form className="flex-column flex-align-center" onSubmit={handleSubmit}>
                        <label>
                            Nom*
                            <InputBase
                                type="text"
                                value={lastname}
                                placeholder="Doe"
                                className="input-form" onChange={(e) => setLastname(e.target.value)}
                                autoFocus={true}
                                required={true}
                            />
                        </label>
                        <label>
                            Prénom*
                            <InputBase
                                type="text"
                                value={firstname}
                                placeholder="John"
                                className="input-form" onChange={(e) => setFirstname(e.target.value)}
                                required={true}
                            />
                        </label>
                        <label>
                            Pseudo*
                            <InputBase
                                type="text"
                                value={pseudo}
                                placeholder="Joe"
                                className="input-form" onChange={(e) => setPseudo(e.target.value)}
                                required={true}
                            />
                        </label>
                        <label>
                            Adresse e-mail*
                            <InputBase
                                type="email"
                                value={email}
                                placeholder="exemple@projets-opacity.fr"
                                className="input-form" onChange={(e) => setEmail(e.target.value)}
                                required={true}
                            />
                        </label>
                        <label>
                            Mot de passe*
                            <InputBase
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                placeholder="**********"
                                className="input-form"
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end" className="visibility-form">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required={true}
                            />
                        </label>
                        <label>
                            Confirmer le mot de passe*
                            <InputBase
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                placeholder="**********"
                                className="input-form"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end" className="visibility-form">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                required={true}
                            />
                        </label>
                        <label>
                            <Checkbox
                                value={showName}
                                className="checkbox-form"
                                onChange={(e) => setShowName(e.target.value)}
                            />
                            Rendre visibles mon nom et prénom
                        </label>
                        <label>
                            <Checkbox
                                value={newsletter}
                                className="checkbox-form"
                                onChange={(e) => setNewsletter(e.target.value)}
                            />
                            S'abonner à la newsletter
                        </label>

                        {error && <p className="error-form">{error}</p>}

                        <Button type="submit" variant="contained" color="primary" className="submit-form"
                                disabled={loading}>{loading ? 'Création en cours...' : 'Créer un compte'}</Button>
                        <p className="subtext">* Obligatoire</p>
                    </form>

                    <div className="registration-register">
                        <p className="subtext">Vous avez déjà un compte ?</p>
                        <Link to="/connexion" className="link-anim">Se connecter</Link>
                    </div>
                </div>
                <p>© 2024 Projets Opacity. All Rights Reserved.</p>
            </section>
        </>
    );
};

export default Register;