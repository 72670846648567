import React, {useState} from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import {Button, InputBase} from "@mui/material";

import AlertDialog from "../../components/Dialog/AlertDialog";
import Meta from "../../components/Head/Meta";

function ResetPasswordRequest() {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage(false);

        // Appeler l'API pour envoyer un mail
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/password-reset/request`, {email});
            setSuccessMessage(true);
        } catch (error) {
            setErrorMessage('Erreur lors de l\'envoie du mail de réinitialisation de votre mot de passe.');
        }
    };

    return (
        <>
            <Meta title="Mot de passe oublié - Projets Opacity"/>
            <section className="login flex-column flex-justify-between flex-align-center">
                <div className="form-login bg-content-primary flex-column flex-align-center flex-justify-start">
                    <div className="title-login flex-column flex-align-center flex-justify-start">
                        <h1>Mot de passe oublié</h1>
                        <p className="subtext">Afin de réinitialiser votre mot de passe, renseignez votre adresse
                            e-mail.</p>
                    </div>

                    <form onSubmit={handleSubmit} className="flex-column flex-align-center">
                        <label>
                            Adresse e-mail
                            <InputBase
                                type="email"
                                value={email}
                                placeholder="exemple@projets-opacity.fr"
                                className="input-form" onChange={(e) => setEmail(e.target.value)}
                                autoFocus={true}
                                required={true}
                            />
                        </label>

                        {errorMessage && <p className="error-form">{errorMessage}</p>}

                        <Button type="submit" variant="contained" color="primary" className="submit-form">
                            Valider
                        </Button>
                    </form>

                    <div className="registration-login">
                        <p className="subtext">Vous avez déjà un compte ?</p>
                        <Link to="/connexion" className="link-anim">Se connecter</Link>
                    </div>
                </div>
                <p>© 2024 Projets Opacity. All Rights Reserved.</p>
            </section>

            {successMessage && (
                <AlertDialog
                    title="Mail de réinitalisation envoyé"
                    description="Le mail de réinitialisation de votre mot de passe a été envoyé avec succès."
                    isOpen={true}
                />
            )}
        </>
    );
}

export default ResetPasswordRequest;