import React, {useState, useEffect} from "react";
import axios from "axios";
import {Button} from '@mui/material';

import './homepage.css';
import Meta from "../../components/Head/Meta";

export default function Homepage() {
    const [numberOfMembers, setNumberOfMembers] = useState(0);
    const jsonLd = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Projets Opacity",
        "url": "https://www.projets-opacity.fr",
        "logo": "https://www.projets-opacity.fr/logo512.png",
        "description": "Bienvenue sur Projets Opacity ! L'association rassemble les passionnés de jeux vidéo et de développement. Découvrez notre univers dès aujourd'hui !"
    };

    useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/users/number-members`)
            .then((response) => {
                setNumberOfMembers(response.data.numberMembers);
            })
            .catch((error) => {
                console.error("Error fetching number of members:", error);
            });
    }, []);

    return (
        <>
            <Meta title="Projets Opacity" jsonLd={jsonLd}
                  description="Bienvenue sur Projets Opacity ! L'association rassemble les passionnés de jeux vidéo et de développement. Découvrez notre univers dès aujourd'hui !"/>
            <h1 className="hide-title">Projets Opacity</h1>
            <div className="homepage flex-column flex-align-center flex-justify-start">
                <img className="logo" src="/assets/images/po_logo_home.png" alt="Logo Projets Opacity"/>
                <div className="content-home flex-column flex-align-center flex-justify-start">
                    <div className="text flex-column">
                        <p><strong>Projets Opacity</strong> est une association loi 1901 qui réunit des passionnés de jeux
                            vidéo et de développement.</p>
                        <p>Découvrez notre univers en plongeant dans les différents mondes que nous avons créés.</p>
                    </div>
                    <div className="members flex-row flex-align-center flex-justify-center">
                        <img src="/assets/images/svg/members.svg" alt="Icon menbres"/>
                        <p>{numberOfMembers} membres</p>
                    </div>
                    <div className="buttons flex-row flex-align-start flex-justify-center">
                        <Button variant="contained" color="secondary" href={'/association/a-propos'}>À propos de
                            l'association</Button>
                        <Button variant="contained" color="primary" href={'/projets'}>Découvrir nos projets</Button>
                    </div>
                </div>
            </div>
        </>
    );
}